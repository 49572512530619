.Root {
  font-weight: 800;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 calc(var(--gutter) * 1.5);
  transition: 0.5s all;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  outline: none;

  .Icon {
    margin-left: var(--gutter);
    max-width: calc(var(--gutter) * 1);
  }

  .IconLeft {
    margin-right: var(--gutter);
    max-width: calc(var(--gutter) * 1);
  }
}

.Root.IsText {
  display: inline;
  padding: 0;
  min-height: initial;
  font-weight: inherit;
  text-decoration: underline;
}

.FullWidth {
  width: 100%;
  max-width: auto;
}

.IsPrimary {
  background-color: var(--color-brand);
  color: var(--color-light);
  border-bottom: 1px solid var(--color-button-primary-border);
  box-shadow: inset 0px -1px 0px 0px var(--color-button-primary-shadow);

  .Icon {
    fill: var(--color-light);
  }

  &.IsLoading {
    background-color: var(--color-gray-3);
    border-color: var(--color-gray-3);
    box-shadow: none;
  }
}

.IsPrimary:hover {
  color: #fff !important;
}

.IsSecondary {
  background-color: var(--color-gray-4);
  color: var(--color-figure);
  box-shadow: inset 0px -1px 0px 0px var(--color-gray-3);
  border-bottom: 1px solid var(--color-gray-3);

  .Icon {
    fill: var(--color-figure);
  }

  &.IsLoading {
    background-color: var(--color-gray-3);
  }
}

.IsSecondary:hover {
  color: var(--color-figure) !important;
}

.IsDark {
  background-color: var(--color-gray-1);
  color: var(--color-fill);

  .Icon {
    fill: var(--color-light);
  }
}

.IsDark:hover {
  color: var(--color-fill) !important;
}

.IsWhite {
  background-color: var(--color-light);
  color: var(--color-figure);
}

.IsWhite:hover {
  color: var(--color-figure) !important;
}

.IsSplash {
  background-color: var(--color-splash);
  color: var(--color-light);
  border-bottom: 1px solid #ab4e19;
  box-shadow: inset 0px -1px 0px 0px #9d4816;
  .Icon {
    fill: var(--color-light);
  }
}

.IsInProgress {
  background-color: var(--color-error);
  color: var(--color-light);
  border-bottom: 1px solid #bd0000;
  box-shadow: inset 0px -1px 0px 0px #9d4816;
}

.IsIcon {
  min-width: auto;
  padding: calc(var(--gutter) * 0.7) calc(var(--gutter) * 1);

  .Icon {
    margin: 0;
  }

  .ButtonText {
    line-height: 0;
  }
}

.IsText .Icon {
  stroke: var(--color-dark);
}

.IsText.IsDisabled {
  color: var(--color-gray-3);
}

.MobileIconWrapper {
  display: none;
}

.LoadingWrapper {
  margin-left: calc(var(--gutter) * 0.5);
  height: 2rem;
  width: 2rem;
}

.ExtraSmall {
  padding: 0 calc(var(--gutter) * 1);
  min-height: 1.8rem;
  font-size: 0.9rem;
}

.Small {
  padding: 0 calc(var(--gutter) * 1);
  min-height: 2rem;
  font-size: 1rem;
}

.Small .Icon {
  transform: scale(0.8);
}

.Medium {
  min-height: 2.8rem;
  font-size: 1.1rem;
}

.Large {
  min-height: 3rem;
  font-size: 1.2rem;
  line-height: 1rem;
}

.ExtraLarge {
  min-height: 3.7rem;
  font-size: 1.2rem;
  line-height: 1rem;
}

.Root:not(.IsIcon):not(.FullWidth):not(.IsText) {
  width: 100%;
  max-width: 20rem;
}

.Root:not(.IsIcon):not(.NoIconAnimation):hover {
  opacity: 0.9;

  .Icon {
    animation: swinging-icon 0.5s;
  }

  .IconLeft {
    animation: swinging-icon-left 0.5s;
  }
}

@keyframes swinging-icon {
  0% {
    margin-left: var(--gutter);
  }
  50% {
    margin-left: calc(var(--gutter) * 0.5);
    margin-right: calc(var(--gutter) * 0.5);
  }
  100% {
    margin-left: var(--gutter);
  }
}

@keyframes swinging-icon-left {
  0% {
    margin-right: var(--gutter);
  }
  50% {
    margin-right: calc(var(--gutter) * 0.5);
    margin-left: calc(var(--gutter) * 0.5);
  }
  100% {
    margin-right: var(--gutter);
  }
}
/**
 * Non mobile
 */

@media (--viewport-mobile-up) {
  .IsMobileButton {
    display: none;
  }
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .Root {
    padding: 0 1.2rem;
  }
  .Root:not(.IsIcon):not(.FullWidth) {
    max-width: 100%;
  }
  .HasMobileIcon .ButtonText {
    display: none;
  }
  .MobileIconWrapper {
    display: block;
  }
  .ExtraLarge {
    font-size: 1.2rem;
  }
}

.CheckmarkList ul {
  list-style-type: none !important; /* Remove default list style */
  margin: 0px !important; /* Remove default margin */
}

.CheckmarkList li {
  background-image: url(/assets/icons/checkout-checkmark.svg);
  background-size: 1.65rem 1.65rem;
  background-repeat: no-repeat;
  padding-left: 2.25rem;
  margin-bottom: 0.5rem;
}

.IsDisabled {
  cursor: default;
  color: var(--color-gray-5) !important;

  .Icon {
    fill: var(--color-gray-5);
  }
}

.IsDisabled:not(.IsText) {
  background-color: var(--color-gray-4) !important;
  border-bottom: 1px solid var(--color-gray-3) !important;
  box-shadow: inset 0px -1px 0px 0px var(--color-gray-3);
}

.IsDisabled:hover {
  color: var(--color-gray-5) !important;
}

.IsBold {
  font-weight: 700 !important;
}

.IsText:hover {
  color: var(--color-brand);
  opacity: 0.9;
}
