.Root.IsWhite circle {
  stroke: var(--color-fill);
}

.Root.HasThickStroke circle {
  stroke-width: 10px;
}

.Root.IsLarge {
  height: 5rem;
  width: 5rem;
}

.Wrapper {
  max-height: 5rem;
  max-width: 5rem;
  margin: auto;
}

.TooLongWaitMessage {
  margin-top: calc(var(--gutter) * 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Button {
  margin-top: calc(var(--gutter) * 1);
}
