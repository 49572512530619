/* .Root {
} */

.List {
  display: flex;
  justify-content: center;
}

.MenuLink {
  color: var(--color-fill);
  text-decoration: none;
  position: relative;
}

.MenuItem {
  font-size: var(--font-size-medium);
  margin-right: calc(var(--gutter) * 2);
  font-weight: var(--font-weight-extra-bold);
}

.MenuLinkHover:after,
.MenuLinkActive:after {
  content: ' ';
  position: absolute;
  height: 10px;
  background-color: var(--color-brand);
  width: 100%;
  bottom: -1.85rem;
  left: 0;
}

.MenuLinkHover:not(.MenuLinkActive):after {
  left: 50%;
  transform: translateX(-50%);
  width: 0%;
  animation: slideBorderIn 0.2s;
  animation-fill-mode: both;
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .List {
    flex-direction: column;
  }

  .MenuLinkActive,
  .MenuLinkHover {
    color: var(--color-brand);
    &:after {
      display: none;
    }
  }

  /*
   * Slide out menu + animation
   */

  .MenuItem {
    position: relative;
    margin-top: var(--gutter);
    margin-right: 0;
    font-size: calc(var(--font-size-medium) * 1.5);

    &:first-child {
      margin-top: 0;
    }
  }

  .MenuOpen .MenuItem {
    animation: menuFadeIn 0.3s linear;
    animation-fill-mode: both;
  }

  .MenuItem:nth-child(1) {
    animation-delay: calc(0.25s * 1);
  }

  .MenuItem:nth-child(2) {
    animation-delay: calc(0.25s * 1.5);
  }

  .MenuItem:nth-child(3) {
    animation-delay: calc(0.25s * 2);
  }

  .MenuItem:nth-child(4) {
    animation-delay: calc(0.25s * 2.5);
  }

  .MenuItem:nth-child(5) {
    animation-delay: calc(0.25s * 3);
  }

  .MenuItem:nth-child(6) {
    animation-delay: calc(0.25s * 3.5);
  }
}

@keyframes menuFadeIn {
  0% {
    opacity: 0;
    top: 100vh;
  }
  75% {
    opacity: 0.5;
    top: 0px;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideBorderIn {
  0% {
    opacity: 0;
    width: 0%;
  }
  100% {
    opacity: 1;
    width: 100%;
  }
}
