.adyen-checkout__payment-method--selected {
  background: #fff !important;
  border: none !important;
}

.adyen-checkout__payment-method {
  border: none !important;
}

.adyen-checkout__button {
  background: #06c167 !important;
  margin-top: 1rem !important;
  border-radius: 0 !important;
}

.adyen-checkout__payment-method__header {
  justify-content: flex-start !important;
}

html .adyen-checkout__input {
  background-color: var(--color-gray-4);
  color: var(--color-figure);
  box-shadow: inset 0px -1px 0px 0px var(--color-gray-3);
  border-bottom: 1px solid var(--color-gray-3);
  padding: 0 calc(var(--gutter) * 0.8);
  border: 0;
  border-radius: 0;
}

.adyen-checkout__input--large {
  width: 100% !important;
}

.adyen-checkout__input--small {
  width: 100% !important;
}

.adyen-checkout__input--focus {
  border: 1px solid #39b548 !important;
  box-shadow: 0 0 0 2px #5fc76b !important;
}

.adyen-checkout__label__text {
  font-weight: bold !important;
}

.adyen-checkout__label--focused .adyen-checkout__label__text {
  color: #39b548 !important;
}

.adyen-checkout__card__exp-cvc .adyen-checkout__field {
  margin: 0 !important;
  width: 47% !important;
}

.adyen-checkout__card__exp-cvc {
  justify-content: space-between;
}

.lvpEoe09xnQiPr56XiHci *,
.lvpEoe09xnQiPr56XiHci :after,
.lvpEoe09xnQiPr56XiHci :before {
  width: 100%;
}

.adyen-checkout-input__inline-validation {
  top: 50% !important;
}

.adyen-checkout__payment-method__details {
  padding: 0 !important;
}

.payment-spinner {
  height: 100%;
}

.payment-spinner:after {
  width: 20px;
  height: 20px;
  position: relative;
  margin: auto;
  top: 6px;
}

.adyen-checkout__status--error {
  border: none;
  white-space: pre-wrap;
}
