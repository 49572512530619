/**
 * Container
 */

.Root {
  margin: auto;
  width: 100%;
  padding: 0 var(--container-padding);
  max-width: var(--layout-container-width);
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .Root {
    padding: 0 var(--container-padding-mobile);
  }
}
