/**
 * Base styling
 */

html {
  font-size: var(--font-size-root-large-desktop);
  line-height: 1.45;
}

@media screen and (--viewport-large-desktop-down) {
  html {
    font-size: var(--font-size-root-large-desktop);
  }
}

@media screen and (--viewport-desktop-down) {
  html {
    font-size: var(--font-size-root-desktop);
  }
}

@media screen and (--viewport-tablet-down) {
  html {
    font-size: var(--font-size-root-tablet);
  }
}

@media screen and (--viewport-mobile-down) {
  html {
    font-size: var(--font-size-root-mobile);
  }
}

body {
  font-family: var(--font-family-default);
  text-size-adjust: 100%; /* Disable ios safari rotation auto scale of font sizes */
  font-weight: var(--font-weight-medium);
}

html,
body,
#___gatsby,
#gatsby-focus-wrapper {
  min-height: 100%;
  height: 100%;
}

/*  On some devices the elements flicker after being touched. 
    This can be prevented by setting the css property */
html,
body {
  -webkit-tap-highlight-color: transparent;
}

/**
 * NProgress
 */

@media (--viewport-mobile-up) {
  #nprogress .spinner {
    top: 1.8rem !important;
    right: 3rem !important;
  }
}

/* hide spinner on mobile */
@media (--viewport-mobile-down) {
  body.checkout #nprogress .spinner {
    display: none;
  }
}

hr {
  border: none;
  height: 1px;
  color: var(--color-gray-3); /* old IE */
  background-color: var(--color-gray-3); /* Modern Browsers */
}

/**
 * Mobile
 */

@media (--viewport-mobile-down) {
  /**
   * Google maps
   */

  .gmnoprint.gm-bundled-control {
    display: none;
  }

  .gm-fullscreen-control {
    display: none !important;
  }

  /**
   * NProgres
   */
  #nprogress .spinner {
    transition: right 0.2s;
  }
  .header-is-shown #nprogress .spinner {
    right: calc(var(--gutter) * 8) !important;
  }

  textarea,
  input.text,
  input[type='text'],
  input[type='button'],
  input[type='tel'],
  input[type='password'],
  input[type='submit'],
  .input-checkbox {
    appearance: none;
    border-radius: 0;
  }
}
