.WithFormatting {
  *:first-child {
    margin-top: 0;
  }

  p {
    margin-bottom: calc(var(--gutter) * 0.5);
    font-size: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: calc(var(--gutter) * 2);
    margin-bottom: calc(var(--gutter) * 1);
  }

  ul {
    list-style: disc;
    margin-left: calc(var(--gutter) * 2);
    margin-bottom: calc(var(--gutter) * 1);
  }

  a:not(.ContentButton) {
    color: var(--color-brand);
    display: inline-block;
  }

  h1 {
    font-size: 1.45rem;
  }

  h2 {
    font-size: 1.65rem;
  }
}

/* if you would need to style a button from WordPress WYSIWYG */
/* .ContentButton {
} */
