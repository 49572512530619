.Root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.PageContent {
  flex: 1;
  margin-top: calc(var(--layout-site-header-height) - 2px);
  display: flex;
  flex-direction: column;
}

.NoFooter .PageContent {
  flex: initial;
}

.HasBlackFadedHero .PageContent {
  margin-top: 0;
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .PageContent {
    margin-top: calc(var(--layout-site-header-height-mobile) - 2px);
  }
}
