/**
 * Helpers
 */

a,
button {
  outline: 0;
}

a {
  color: var(--color-figure);
  text-decoration: underline;
}
