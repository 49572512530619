/**
 * Fonts
 */

/**
 * Gilroy
 */
/* @font-face {
  font-family: "Gilroy";
  src: local("Gilroy Thin"), local("Gilroy-Thin"),
    url("Gilroy-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Heavy Italic"), local("Gilroy-HeavyItalic"),
    url("Gilroy-HeavyItalic.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Thin Italic"), local("Gilroy-ThinItalic"),
    url("Gilroy-ThinItalic.woff2") format("woff2");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy SemiBold Italic"), local("Gilroy-SemiBoldItalic"),
    url("Gilroy-SemiBoldItalic.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Bold Italic"), local("Gilroy-BoldItalic"),
    url("Gilroy-BoldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy UltraLight"), local("Gilroy-UltraLight"),
    url("Gilroy-UltraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Regular Italic"), local("Gilroy-RegularItalic"),
    url("Gilroy-RegularItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Light Italic"), local("Gilroy-LightItalic"),
    url("Gilroy-LightItalic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Black"), local("Gilroy-Black"),
    url("Gilroy-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy SemiBold"), local("Gilroy-SemiBold"),
    url("Gilroy-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy ExtraBold Italic"), local("Gilroy-ExtraBoldItalic"),
    url("Gilroy-ExtraBoldItalic.woff2") format("woff2");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Medium Italic"), local("Gilroy-MediumItalic"),
    url("Gilroy-MediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy UltraLight Italic"), local("Gilroy-UltraLightItalic"),
    url("Gilroy-UltraLightItalic.woff2") format("woff2");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
} */

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Light'), local('Gilroy-Light'),
    url('/assets/fonts/gilroy/Gilroy-Light.woff2') format('woff2'),
    url('/assets/fonts/gilroy/Gilroy-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: "Gilroy";
  src: local("Gilroy Heavy"), local("Gilroy-Heavy"),
    url("Gilroy-Heavy.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
} */

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Medium'), local('Gilroy-Medium'),
    url('/assets/fonts/gilroy/Gilroy-Medium.woff2') format('woff2'),
    url('/assets/fonts/gilroy/Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: "Gilroy";
  src: local("Gilroy Bold"), local("Gilroy-Bold"),
    url("Gilroy-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}*/

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy ExtraBold'), local('Gilroy-ExtraBold'),
    url('/assets/fonts/gilroy/Gilroy-ExtraBold.woff2') format('woff2'),
    url('/assets/fonts/gilroy/Gilroy-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

/*
@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Black Italic"), local("Gilroy-BlackItalic"),
    url("Gilroy-BlackItalic.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Regular"), local("Gilroy-Regular"),
    url("Gilroy-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */

@font-face {
  font-family: 'Font Awesome 5 Free';
  src: url('//db.onlinewebfonts.com/t/061b39e95e1a0bd25e8d294af8596b2a.eot');
  src: url('//db.onlinewebfonts.com/t/061b39e95e1a0bd25e8d294af8596b2a.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/061b39e95e1a0bd25e8d294af8596b2a.woff2')
      format('woff2'),
    url('//db.onlinewebfonts.com/t/061b39e95e1a0bd25e8d294af8596b2a.woff')
      format('woff'),
    url('//db.onlinewebfonts.com/t/061b39e95e1a0bd25e8d294af8596b2a.ttf')
      format('truetype'),
    url('//db.onlinewebfonts.com/t/061b39e95e1a0bd25e8d294af8596b2a.svg#Font Awesome 5 Free')
      format('svg');
}
