.Root {
  z-index: 100;
  height: var(--layout-site-header-height);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  /* transition: opacity 0.2s, top 0.2s background-color 0.2s; */
  transition: all 0.2s;
  opacity: 1;
  display: flex;
  color: var(--color-fill);
  max-width: 100vw;
}

.Root:not(.IsTransperant.IsWithinTop) {
  /* gradient transitions not yet supported, but for some lucky future day, when it is
     below code should work
     https://stackoverflow.com/questions/6542212/use-css3-transitions-with-gradient-backgrounds
     */
  /* background-color: var(--color-figure); */
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgb(0 0 0) 100%);
}

@media (--viewport-mobile-up) {
  .IsTransperant.IsWithinTop {
    /* background: rgb(0, 0, 0); */
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 189, 255, 0) 100%
    );
  }
}

.LogoWrapper {
  width: var(--logo-wrapper-width);
  display: flex;
  justify-content: center;
  align-items: center;
}
.Logo {
  width: 100%;
}

.CenterHeader {
  display: flex;
  align-items: center;
  font-size: var(--font-size-medium);
}

.Addons {
  margin-right: var(--gutter);
  display: flex;
  align-items: center;

  a {
    color: var(--color-brand);
  }
}

.MyAccount {
  padding: calc(var(--gutter) * 0.7) calc(var(--gutter) * 1);
  /* border: 1px solid var(--color-gray-1); */

  .UserIcon {
    height: 1rem;
    path {
      fill: var(--color-light);
    }
  }
}

.BurgerMenu {
  display: none;
}

.IsTransperant .MyAccount {
  border-color: var(--color-figure);
  background-color: rgb(0, 0, 0, 0.1);
}

.ContainerClass {
  display: flex;
}

.PipContainerClass {
  max-width: 525px;
  display: flex;
  justify-content: space-between;
}

.Start,
.End {
  min-width: 10rem;
  display: flex;
  z-index: 110;
}

.End {
  justify-content: flex-end;
}

.End > :not(:first-child) {
  margin-left: calc(var(--gutter) * 1.5);
}

.NavWrapper {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  white-space: nowrap;
}

/* Visibility */
.NavUp {
  transition: opacity 0.2s, top 0.2s;
  top: calc(var(--layout-site-header-height) * -1);
}

.NavDown {
  transition: opacity 0.2s, top 0.2s;
  top: 0;
}

.SignupButton {
  min-width: auto;
  white-space: nowrap;
}

.NavMenuButtonsMobile {
  display: none;
}

/*
 * For mobile
 */

@media (--viewport-mobile-down) {
  .Root {
    height: var(--layout-site-header-height-mobile);
    background-color: var(--color-figure);
  }
  .End .SignupButton {
    display: none;
  }

  .ContainerClass {
    justify-content: space-between;
  }

  .Start,
  .End {
    min-width: auto;
  }

  .Addons {
    margin-right: 0;
    font-size: 0.8rem;
  }

  .End {
    min-width: auto;
    height: 2rem;
    align-items: center;
  }

  .LogoWrapper {
    width: 4rem;
  }

  .MyAccount {
    border: none;
    padding: 0;
    min-height: auto;

    /* align with burger menu */
    margin-top: 2px;
  }

  .MyAccount .UserIcon {
    height: 1.6rem;
  }

  /**
   * Slide out menu
   */

  .NavWrapper {
    position: fixed;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    left: -100%;
    top: 0;
    transition: left 0.3s;
    height: 100vh;
    /* height: fill-available; */ /* https://github.com/postcss/autoprefixer/issues/1035 */
    height: stretch;
    width: 70vw;
    background-color: var(--color-dark);
    z-index: 100;
    padding: var(--container-padding-mobile);
    padding-top: calc(var(--gutter) * 6);
  }

  .NavWrapper:before {
    position: absolute;
    content: ' ';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 200vw;
    opacity: 0;
    transition: all 1s;
    pointer-events: none;
  }

  .MenuOpen .NavWrapper {
    left: 0;
  }

  .MenuOpen .NavWrapper:before {
    opacity: 1;
  }

  /**
   * Burger menu
   */

  .BurgerMenu {
    display: block;
    width: 2.2rem;
    height: 20px;
    position: relative;
    transform: rotate(0deg);
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    min-height: auto;
  }

  .MenuOpen .End,
  .MenuOpen .Start {
    z-index: 110;
  }

  .BurgerMenu .Pole {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: var(--color-fill);
    border-radius: 1px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: all 0.25s ease-in-out;
  }

  .BurgerMenu .Pole:nth-child(1) {
    top: 0px;
  }

  .BurgerMenu .Pole:nth-child(2) {
    top: 50%;
  }

  .BurgerMenu .Pole:nth-child(3) {
    top: 100%;
  }

  .MenuOpen .BurgerMenu .Pole:nth-child(1),
  .MenuOpen .BurgerMenu .Pole:nth-child(3) {
    top: 40%;
    transform: rotate(135deg);
  }

  .MenuOpen .BurgerMenu .Pole:nth-child(2) {
    opacity: 0;
    left: -60px;
  }

  .MenuOpen .BurgerMenu .Pole:nth-child(3) {
    transform: rotate(-135deg);
  }

  .NavMenuButtonsMobile {
    display: block;
    width: 100%;
    transition: all 0.5s;
    position: relative;
    /* using min-height: -webkit-fill-available; instead */
    /* margin-bottom: calc(52px + calc(var(--gutter) * 2)); */
  }

  .NavMenuButtonsMobile .SignupButton {
    width: 100%;
    transition: all 0.5s;
    transition-delay: 1s;
    position: absolute;
    left: -100vw;
    top: -3rem;
  }

  .MenuOpen .NavMenuButtonsMobile .SignupButton {
    left: 0vw;
  }
}

/*
 * For tablet
 */
@media (--viewport-mobile-up) and (--viewport-tablet-down) {
  /* allow for horizontal scrolling */
  .NavWrapper {
    height: var(--layout-site-header-height);

    justify-content: flex-start;
    overflow-x: scroll;
    /* hide scrollbar */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
}
