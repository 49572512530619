/**
 * CSS reset
 */

/* stylelint-disable */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* HTML5 display definitions */

/*
// Correct `block` display not defined for any HTML5 element in IE 8/9.
// Correct `block` display not defined for `details` or `summary` in IE 10/11
// and Firefox.
// Correct `block` display not defined for `main` in IE 11.
*/

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

/*
// 1. Correct `inline-block` display not defined in IE 8/9.
// 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
*/

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

/*
// Address `[hidden]` styling not present in IE 8/9/10.
// Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
*/

[hidden],
template {
  display: none;
}

/* Links */

/*
// Remove the gray background color from active links in IE 10.
*/

a {
  background-color: transparent;
}

/*
// Improve readability of focused elements when they are also in an
// active/hover state.
*/

a:active,
a:hover {
  outline: 0;
}

/* Text-level semantics */

/*
// Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
*/

b,
strong {
  font-weight: bold;
}

/*
// Address variable `h1` font-size and margin within `section` and `article`
// contexts in Firefox 4+, Safari, and Chrome.
*/

h1 {
  font-size: 2em;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

/*
// Prevent `sub` and `sup` affecting `line-height` in all browsers.
*/

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content */

/*
// Remove border when inside `a` element in IE 8/9/10.
*/

img {
  border: 0;
}

/*
// Correct overflow not hidden in IE 9/10/11.
*/

svg:not(:root) {
  overflow: hidden;
}

/* Grouping content */

/*
// Address margin not present in IE 8/9 and Safari.
*/

figure {
  margin: 1em 40px;
}

/*
// Address differences between Firefox and other browsers.
*/

hr {
  box-sizing: content-box;
  height: 0;
}

/*
// Contain overflow in all browsers.
*/

pre {
  overflow: auto;
}

/* Forms */

/*
// Known limitation: by default, Chrome and Safari on OS X allow very limited
// styling of `select`, unless a `border` property is set.
*/

/*
// 1. Correct color not being inherited.
//    Known issue: affects color of disabled elements.
// 2. Correct font properties not being inherited.
// 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
// 4. Removes inner shadow on mobile Safari
*/

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
  box-shadow: inset 0px 0px 0px 0px transparent;
  background: transparent;
  font-family: var(--font-family-default);
  font-size: 16px;
}

/*
// Address `overflow` set to `hidden` in IE 8/9/10/11.
*/

button {
  overflow: visible;
}

/*
// Remove border radius in Chrome 62+
*/
button {
  border-radius: 0;
}

/*
// Address inconsistent `text-transform` inheritance for `button` and `select`.
// All other form control elements do not inherit `text-transform` values.
// Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
// Correct `select` style inheritance in Firefox.
*/

button,
select {
  text-transform: none;
}

/*
// 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
//    and `video` controls.
// 2. Correct inability to style clickable `input` types in iOS.
// 3. Improve usability and consistency of cursor style between image-type
//    `input` and others.
*/

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}

/*
// Re-set default cursor for disabled elements.
*/

button[disabled],
html input[disabled] {
  cursor: default;
}

/*
// Remove inner padding and border in Firefox 4+.
*/

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/*
// Address Firefox 4+ setting `line-height` on `input` using `!important` in
// the UA stylesheet.
*/

input {
  line-height: normal;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: 0;
}

/*
// It's recommended that you don't attempt to style these elements.
// Firefox's implementation doesn't respect box-sizing, padding, or width.
//
// 1. Address box sizing set to `content-box` in IE 8/9/10.
// 2. Remove excess padding in IE 8/9/10.
*/

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

/*
// Fix the cursor style for Chrome's increment/decrement buttons. For certain
// `font-size` values of the `input`, it causes the cursor style of the
// decrement button to change from `default` to `text`.
*/

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}

/*
// 1. Address `appearance` set to `searchfield` in Safari and Chrome.
// 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
*/

input[type='search'] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

/*
// Remove inner padding and search cancel button in Safari and Chrome on OS X.
// Safari (but not Chrome) clips the cancel button when the search input has
// padding (and `textfield` appearance).
*/

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type='date'] {
  position: relative;
}

/* create a new arrow, because we are going to mess up the native one
see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
input[type='date']:after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f073';
  color: var(--color-brand);
  padding: 0 5px;
  font-size: 25px;
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

/* adjust increase/decrease button */
input[type='date']::-webkit-inner-spin-button {
  z-index: 1;
}

/* adjust clear button */
input[type='date']::-webkit-clear-button {
  z-index: 1;
}

/*
// Remove default vertical scrollbar in IE 8/9/10/11.
*/

textarea {
  overflow: auto;
}

/* Tables */

/*
// Remove most spacing between table cells.
*/

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

/*
// Remove tabIndex component outline
*/
[tabindex='-1'] {
  outline: none;
}

/*
// Remove Default button Style
*/
input[type='button'],
input[type='submit'],
input[type='reset'],
input[type='file']::-webkit-file-upload-button,
button {
  border-width: 0;
}

/*
// Remove IE10's “clear field” X button on certain inputs
*/
input::-ms-clear {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/**
 * Unordered lists
 */

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/**
 * Fieldset
 */

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

hr {
  margin: var(--gutter) 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
