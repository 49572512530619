.Root {
  background-color: var(--color-figure);
  color: var(--color-fill);
  font-size: var(--font-size-small);
  padding: var(--section-padding) 0;
}

.Root a {
  color: var(--color-gray-3);
  text-decoration: none;

  &:hover {
    color: var(--color-gray-4);
    text-decoration: underline;
  }
}

.Logo {
  max-width: 8rem;
  margin-bottom: calc(var(--gutter) * 3);
}
.MobileLogo {
  display: none;
}

.Sections {
  display: flex;
  justify-content: space-between;
}

/* .Section {
} */

.CompanyDetails {
  margin-bottom: calc(var(--gutter) * 2);
}

.SocialIcon {
  background-color: var(--color-gray-1);
  margin-right: calc(var(--gutter) * 0.5);
  padding: calc(var(--gutter) * 0.7) calc(var(--gutter) * 0.8);
  width: 3rem;
  height: 3rem;

  &:last-child {
    margin-right: 0;
  }
}

.SocialIcon img {
  height: 1.2rem;
  object-position: center;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%)
    contrast(103%);
}

.SectionLink {
  margin-bottom: calc(var(--gutter) * 0.5);
}

.SectionHeader {
  font-weight: var(--font-weight-extra-bold);
  font-size: calc(var(--font-size-medium) * 1.2);
  line-height: calc(var(--font-size-medium) * 1.2);
  margin-bottom: calc(var(--gutter) * 2);
}

/**
* For desktop
*/

@media (--viewport-desktop-up) {
  .MinimalFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gutter);

    .Section {
      display: flex;
      gap: var(--gutter);
    }

    .CompanyDetailsMini:not(:first-child) {
      color: var(--color-gray-2);
    }
  }
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .Root {
    padding: calc(var(--gutter) * 2) 0;
  }
  .MobileLogo {
    display: block;
    max-width: 8rem;
  }

  .Sections {
    flex-direction: column;
  }

  .Sections .Section {
    flex-basis: 50%;
    margin-top: calc(var(--gutter) * 2);
    &:nth-child(2) {
      margin-top: 0;
    }
  }

  .Sections.MinimalFooter {
    flex-direction: row-reverse;
    .Section {
      margin: 0;
    }
  }

  .Sections .Section:first-child {
    order: 10;
  }

  .SectionHeader {
    margin-bottom: var(--gutter);
  }

  .MinimalFooter {
    flex-direction: row;

    .Section:not(:first-child) {
      flex: 1;
    }
    .Section:first-child {
      margin-right: calc(var(--gutter) * 4);
    }
  }
}
