/**
 * Helpers
 */

/* Screen readers only */
.sr-only {
  position: fixed;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
  height: 0;
  opacity: 0;
}

@media (--viewport-mobile-down) {
  br.mobile {
    display: block;
  }
  br.desktop {
    display: none;
  }
}
@media (--viewport-mobile-up) {
  br.desktop {
    display: block;
  }
  br.mobile {
    display: none;
  }
}
